<template>
  <nuxt/>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Empty',
  head () {
    return {
      bodyAttrs: {
        class: 'layout-empty'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  }
}
</script>

<style>
.layout-empty {
  min-height: 100vh;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
}
</style>
