import { render, staticRenderFns } from "./mlearn.vue?vue&type=template&id=d8bd58c0&scoped=true&"
import script from "./mlearn.vue?vue&type=script&lang=js&"
export * from "./mlearn.vue?vue&type=script&lang=js&"
import style0 from "./mlearn.vue?vue&type=style&index=0&id=d8bd58c0&prod&lang=scss&scoped=true&"
import style1 from "./mlearn.vue?vue&type=style&index=1&id=d8bd58c0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8bd58c0",
  null
  
)

export default component.exports