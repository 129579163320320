<template>
  <div>
    <MLMenu/>
    <div class="ml-content">
      <nuxt/>
    </div>
    <MLFooter/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MLMenu from '~/components/mlearn/layout/menu/menu.vue'
import MLFooter from '~/components/mlearn/layout/footer/footer.vue'
export default {
  components: {
    MLMenu,
    MLFooter
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  }
}
</script>
<style lang="scss" scoped>
  .ml-content{
    margin-top: 95px;
    @media (max-width:1366px) {
      margin-top: 90px;
    }
    @media (max-width:576px) {
      margin-top: 60px;
    }
  }
 .app-fixed{
   position: fixed !important;
   top:0;
   left: 0;
   right: 0;
   z-index: 9000;
 }
  footer {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      padding: 2rem 0;
    }
    .service {
      padding-left: 12px !important;
    }
    .footer-logo {
      margin-bottom: 1.5rem;
      img {
        width: 212px;
        height: auto;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {
          width: 131px;
        }
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
    .company {
      padding-right: 5.25rem;
      @media (max-width: 992px) {
        padding-right: 0;
      }
    }
    .company-info {
      line-height: 32px;
      margin-bottom: 10px;
      font-size: 16px;
      &.company-name {
        font-size: 20px;
      }
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {
        font-size: 1rem;
        margin-bottom: 4px;
        &.company-name {
          font-size: 1.125rem;
        }
      }
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 28px;
        &.company-name {
          font-size: 15px;
        }
      }
    }
    .notify-website{
      width: 11rem;
    }
    .ft-block-title{
      margin-top: 2.875rem;
      margin-bottom: 1rem;
      line-height: 1rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {
        margin-top: 1.875rem;
      }
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    a.ft-block__content {
      line-height: 27px;
      font-size: 16px;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {
        font-size: 16px;
        margin-bottom: 0 !important;
      }
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .iconby {
      width: 1.25rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    .download-app{
      .qr-app{
        img{
          width:4.5rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
        }
      }
      .app-links {
        width: 7.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
    .connect-youtube {
      width: 1.75rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    .connect-facebook {
      width: 1.25rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    .footer-divider {
      margin-top: 24px;
      margin-bottom: 20px;
      height: 1px;
      background-color: #e6e6e6;
    }
    .copyright-footer {
      font-size: 14px;
    }
  }

  </style>
  <style lang="scss">
                      // VARIABLES
  $primaryColor: #00c2c5;
  $appBG: #FAFDFF;
  $darkGreyColor: #333333;
  $lightGreyColor: #3a4149;
  $slightGreyColor: #676767;
  $textDark: #333333;
  $boderGreyColor: #ececec;
  $slightGreyColorSub2: #777777;
  $slightGreyColor: #737373;
  $scale: 0.9;
  $darkBlueColor: #4086ff;
  $navbarYellowColor: #ffa216;
  $lightBlueColor: #669eff;
  $semanticScColor: #7bbf6a;
  // HELPER
  .ytp-impression-link {
    display: none !important;
  }
  body {
    figure {
      margin: 0;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .show-on-mobile {
      display: none !important;
    }
    .hide-on-mobile-flex {
      display: flex !important;
    }
  }
  .bg-success-mod {
    background-color: #7a9c59;
  }
  .color-primary {
    color: $primaryColor !important;
  }
  .color-darkgrey {
    color: $darkGreyColor !important;
  }
  .color-lightgrey {
    color: $lightGreyColor !important;
  }
  .color-slightgrey {
    color: $slightGreyColor !important;
  }
  .color-slightgrey2 {
    color: $slightGreyColorSub2 !important;
  }
  .bg-color-primary {
    background-color: $primaryColor !important;
  }
  .bg-color-darkgrey {
    background-color: $darkGreyColor !important;
  }
  .bg-color-lightgrey {
    background-color: $lightGreyColor !important;
  }
  .bg-color-darkblue {
    background-color: $darkBlueColor !important;
  }
  .bg-color-semanticsc {
    background-color: $semanticScColor !important;
  }
  .text-dark {
    color: $textDark !important;
  }
  .dark-blue-color {
    background-color: $darkBlueColor !important;
  }
  .bg-color-hover {
    background-color: $navbarYellowColor;
  }

  .fw-500 {
    font-weight: 500 !important;
  }
  .fw-700 {
    font-weight: 700 !important;
  }
  .fw-900 {
    font-weight: 900 !important;
  }
  .fw-400-italic {
    font-weight: 400 !important;
    font-style: italic !important;
  }
  .no-decoration {
    text-decoration: none !important;
  }
  .menu-sidebar-wr {
    display: none;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  @for $i from 10 through 32 {
    .font-size-#{$i} {
      font-size: #{$i}px !important;
    }
  }
  @for $i from 1 through 4 {
    .limit-line-#{$i} {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: #{$i};
      line-clamp: #{$i};
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
  // BS4 OVERWRITE
  body {
    .dropdown-toggle::after {
      display: none;
    }
  }
  .bread-crumb-ctm {
    .each-tab-bc {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      text-decoration: none;
      &::after {
        content: '/';
      }
      &.on-active {
        font-weight: bold;
        color: #00c2c5;
        &::after {
          display: none;
        }
      }
    }
  }
  .btn-outline-primary {
    color: $primaryColor;
    background-color: transparent;
    background-image: none;
    border-color: $primaryColor;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      color: #fff;
      background-color: $primaryColor;
      border-color: $primaryColor;
      img {
        filter: brightness(0) invert(1);
      }
    }
    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 0.2rem #00c2c591;
      outline-color: #00c2c591;
    }
  }
  .btn-no-outline {
    &:focus,
    &:active,
    &:focus-within {
      outline-color: transparent;
      box-shadow: none;
    }
  }
  // SHARED STYLE
  .fade-away {
    opacity: 0 !important;
    pointer-events: none;
    width: 0 !important;
  }

  // scroll to top
  .btn-scroll-to-top {
    right: 20px;
    bottom: 20px;
    border: 2px solid #f0f0f0;
    background-color: #ffa216;
    transition: color 0.1s, opacity 0.3s, transform 0.3s;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    z-index: 2;
    color: #ffa216;
    transition: 0.25s ease;
    opacity: 0;
    transform: translateY(30%);
    &--on-show {
      opacity: 1;
      transform: translateY(0);
    }
    i {
      font-size: 16px;
    }
    &:hover {
      background-color: #ffa216;
      transform: translateY(-3px);
      // color: #fff;
    }
  }

  // facebook messenger
  .btn-fb-messenger {
    display: inline-block;
    left: 20px;
    bottom: 20px;
    width: 52px;
    height: 52px;
    z-index: 999;
    box-shadow: 0 0 7px 2px #c9c9c9;
    border-radius: 99px;
  }
  // FOOTER
  footer {
    background-color: $appBG;
  }

  .show-on-mobile-flex {
    display: none !important;
  }

  // REPONSIVE
  @media only screen and (max-width: 992px) {
    .show-lower-991 {
      display: block !important;
    }
    .hide-lower-991 {
      display: none !important;
    }
  }
  @media only screen and (max-width: 560px) {
    body {
      .show-on-mobile {
        display: block !important;
      }
      .show-on-mobile-flex {
        display: flex !important;
      }
      .hide-on-mobile-flex {
        display: none !important;
      }
      .ft-divider-mb {
        border-top: 1px solid #a8a8a8;
      }
    }
    .hide-on-mobile {
      display: none !important;
    }
  }
  // .swiper{
  //   overflow: unset !important;
  //   .swiper-wrapper{
  //     .swiper-slide{
  //       opacity: 0;
  //       transition: all .5s;
  //       &.swiper-slide-visible{
  //         opacity: 1;
  //       }
  //     }
  //   }
  // }
</style>
